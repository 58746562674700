<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Global Settings
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Setting</li>          
          <li class="breadcrumb-item active" aria-current="page">Global Settings</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<b-modal
						:centered="true"
						:hide-footer="true"
						:title="titleModal"
						v-model="modalForm"
					>
						<b-row>
              <template v-if="stateCrud == 'upload-image'">
                <b-col class="mb-3" lg="12">
                  <b-row>
                    <b-col lg="6"><label>Image</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>
                  <input type="file" id="image" ref="image" v-on:change="handleImageUpload()"/>
                </b-col>                
              </template>
              <template v-else-if="stateCrud == 'upload-file'">
              </template>
              <template v-else>
                <b-col class="mb-3" lg="12">
                  <b-row>                
                    <b-col lg="6"><label>Type</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>                
                  <b-form-select v-model="form.type" :options="options"></b-form-select>

                  <template v-if="form.type != ''">
                    <b-row class="pt-3">
                      <b-col lg="6"><label>Key</label></b-col>
                      <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                    </b-row>                  
                    <input type="text" v-model="form.key" class="form-control form-control-lg" placeholder="Key">                  

                    <template v-if="form.type == 'text'">                  
                      <b-row class="pt-3">
                        <b-col lg="6"><label>Value</label></b-col>
                        <!-- <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                        -->
                      </b-row>                    
                      <input type="text" v-model="form.value" class="form-control form-control-lg" placeholder="Value">
                    </template>
                    <template v-else-if="form.type == 'textarea'">
                      <b-row class="pt-3">
                        <b-col lg="6"><label>Value</label></b-col>
                        <!-- <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                        -->
                      </b-row>                    
                      <vue-editor v-model="form.value" :editorToolbar="customToolbar"></vue-editor>
                    </template>
                  
                    <b-row class="pt-3">
                      <b-col lg="6"><label>Class</label></b-col>
                      <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                    </b-row>
                    <input type="text" v-model="form.class" class="form-control form-control-lg" placeholder="Class">
                    
                    <b-row class="pt-3">
                      <b-col lg="6"><label>Style</label></b-col>
                      <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                    </b-row>
                    <input type="text" v-model="form.style" class="form-control form-control-lg" placeholder="Style">                  
                  </template>                
                </b-col>                 
              </template>                            
							<b-col class="mb-3 mb-xl-0" lg="12">
								<b-input-group>
									<b-button
										variant="primary"
										href="javascript:void(0)"
										@click="sendPayload()">Save
									</b-button>
								</b-input-group>
							</b-col>                
						</b-row>
					</b-modal>					
					<div class="card-body">
						<b-form-group
							label="Filter"
							label-for="filter-input"
							label-cols-md="3"
							label-align-md="right"
							label-size="md"
							class="mb-1"
						>
							<b-input-group size="md">
								<b-form-input
									id="filter-input"
									type="search"
									placeholder="Type to Search"
									style="margin-right: 0.5rem"
								></b-form-input>

								<b-input-group-append>
									<b-button
										style="margin-right: 0.5rem"
										>Search</b-button
									>
									<b-button >Clear</b-button
									>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>							
					</div>
				</div>
      </div>

      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-head">
						<div class="row col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2">
							<button type="button"  
								class="btn btn-sm btn-primary btn-icon-text"
								@click="openAddModal()">
								Add
								<i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
							</button>							
						</div>
					</div>
					<div class="card-body">
							<b-table 
							responsive 
							show-empty
							:striped="tableConfig.striped"
							:bordered="tableConfig.bordered"
							:borderless="tableConfig.borderless"
							:outlined="tableConfig.outlined"
							:small="tableConfig.small"
							:hover="tableConfig.hover"
							:dark="tableConfig.dark"
							:fixed="tableConfig.fixed"
							:foot-clone="tableConfig.footClone"
							:no-border-collapse="tableConfig.noCollapse"
							:head-variant="tableConfig.headVariant"
							:table-variant="tableConfig.tableVariant"
							:items="data" 
							:fields="fields">
								<template #cell(index)="data">
									{{ data.index + 1 }}
								</template>						
								<template v-slot:cell(created_at)="row">
									{{ normalDateTime(row.item.created_at) }}
								</template>
								<template v-slot:cell(updated_at)="row">
									{{ normalDateTime(row.item.updated_at) }}
								</template>
								<template v-slot:cell(actions)="row">
									<button type="button"  
										class="btn btn-sm btn-warning btn-icon-text"
										@click="openEditModal(row.item.uuid, row.item)">
										Edit
										<i class="mdi mdi-file-check btn-icon-append"></i>
									</button>
									<br>
									<br>
                  <button type="button"  
                    class="btn btn-sm btn-success"
                    v-if="row.item.type == 'image'"
                    @click="openUploadImageModal(row.item.uuid)">
                    Image
                    <i class="text-center mdi mdi mdi-upload"></i>
                  </button>

									<!-- <button type="button"  
										class="btn btn-sm btn-danger btn-icon-text"
										@click="openDeleteModal(row.item.uuid, row.item)">
										Delete
										<i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
									</button> -->
									<br>
								</template>							
							</b-table>
							<b-pagination
								v-model="page"
								:total-rows="totalRows"
								:per-page="limit"
								align="fill"
								size="md"
								class="mb-1"
								v-on:click.native="callback"
							></b-pagination>
					</div>
				</div>
      </div>			
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",
      customToolbar: [
          [{'size': ['small', false, 'large']}],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link'],
          ['emoji'],
      ],      
			tableConfig : packageGlobals.table,
      data: [],
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      selected: null,
      options: [
        { value: 'text', text: 'text' },
        { value: 'textarea', text: 'textarea' },
        { value: 'image', text: 'image' },        
        { value: 'file', text: 'file' }
      ],      
      form: {
				key: "",
        value: "",
        type: "",
        class: "",
        style: ""
      },
      formUpload: {

      },      					
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'key', label: 'Key', class: "text-center", sortable: true},
//				{key: 'value', label: 'Value', class: "text-center", sortable: true},
				{key: 'type', label: 'Type', class: "text-center", sortable: true},                
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },
		handleImageUpload(){			
			this.formUpload.image = this.$refs.image.files[0];			
		},    
    getAll: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/global-setting?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
			})				
			
    },
     searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openUploadImageModal: function (id) {
      this.titleModal = "Upload Image"
      this.stateCrud = "upload-image"
      this.idKey = id
      this.modalForm = true      
    },    
    openAddModal: function () {
      this.selected = ''
      this.form.type = ""
      this.form.value = ""
      this.form.key = ""            
      this.titleModal = "Add Global Settings"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Global Settings"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.type = item.type
      this.form.key = item.key
      this.form.value = item.value
      this.form.class = item.class
      this.form.style = item.style                        
      this.modalForm = true
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
				console.log(result.value);
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/reference/global-setting/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
          })
        }
      })
    },		
    sendPayload: function () {              
        // var loader = this.$loading.show({
        //   container: this.fullPage ? null : this.$refs.containerForm,
        //   canCancel: true,
        //   onCancel: this.onCancel,
        // });

      if (this.stateCrud == "add") {
        let checkField = packageGlobals.checkField(
          [
            {value : this.form.key, message : 'field key is required', key:'key'},
            // {value : this.form.value, message : 'field value is required', key:'value'},
            {value : this.form.type, message : 'field type is required', key:'type'}                            
          ]
        )
        var flag = checkField.flag, message = checkField.message
        if (flag == 0) {
          this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/reference/global-setting`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/reference/global-setting`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })          
        }
        else
        {
          this.$swal.fire({
            title: "Ups, There is a mistake",
            text: message,
            icon: "error",
            confirmButtonText: "Ok",
          });        
        }


      } else if (this.stateCrud == "edit") {
        this.updated_by = localStorage.getItem("uuid")					
        packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
        axios.put(`${packageStore.mainApi}/api/web/reference/global-setting/${this.idKey}`,this.form)
        .then((response) => {
          this.responseAxios(response)
        })
      } else if (this.stateCrud == "upload-image") {
        this.updated_by = localStorage.getItem("uuid")
        let formData = new FormData();
        formData.append('file', this.formUpload.image);          
        packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
        // this.configAxios.headers["Content-Type"] = "multipart/form-data";
        axios.put(`${packageStore.mainApi}/api/web/reference/global-setting/upload/${this.idKey}/${localStorage.getItem("uuid")}`,formData)
        .then((response) => {
          this.responseAxios(response)
        })
      }        
        // loader.hide()
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)		
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>